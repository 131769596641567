<template>
<div>
    <Loading v-if="$global.state.loading" />
    <template v-if="!$global.state.loading">
        <div>
            <!-- <v-toolbar flat outlined> -->
            <v-alert color="secondary" text outlined border="left">
                <v-toolbar-title>
                    <h4 class="blacko--text">{{ $t('ism-almaamlh') }} : ( {{ $route.params.orderName }} )</h4>
                </v-toolbar-title>
            </v-alert>
            <!-- </v-toolbar> -->
            <v-timeline dense>
                <v-timeline-item v-for="(item, index) in items" :key="index" :color="item.isComplete ? 'teal' : 'blue darken-3'" fill-dot>
                    <!-- <span>Tus eu perfecto</span> -->
                    <v-card id="card">
                        <v-toolbar :color="item.isComplete ? 'teal' : 'blue darken-3'" dark tile>
                            <v-toolbar-title>
                                <v-card-title>
                                    <h3>{{ item.node.name }}</h3>
                                    <v-btn v-if="!isGuest" color="white" @click="openUsers(item.node.group.id)" outlined class="mr-5">{{item.node.group.name}}</v-btn>
                                </v-card-title>
                            </v-toolbar-title>
                            <v-spacer />
                            <h4 style="color: #122940" v-if="item.isComplete">
                                <div dir="ltr">
                                    {{ new Date(item.completedAt).toISOString().split("T")[0] }}
                                    ,
                                    <span>
                                        {{
                      new Date(item.completedAt).toLocaleString().split(",")[1]
                      }}
                                    </span>
                                </div>
                            </h4>
                        </v-toolbar>
                        <v-container fluid>
                            <v-alert outlined border="left" prominent :color="item.isComplete ? 'teal' : 'blue darken-3'" text>
                                <v-toolbar flat outlined :color="item.isComplete ? '#A6DAD5' : '#ADC9E9'" rounded="" v-if="item.user != null">
                                    <h3 v-if="item.isComplete == true" style="color: #122940">
                                        {{ $t('mtkhth-alijraa') }}||
                                        <v-chip label>
                                            <h3 >{{ item.user.fullName }} ( {{ item.user.userName }} )</h3>
                                        </v-chip>||=||
                                        <v-chip label color="primary" outlined>
                                            <h3>{{ item.user.jobTitle }}</h3>
                                        </v-chip>||=||
                                        <v-chip label color="secondary" outlined>
                                            <h3>{{ item.user.organization.name }}</h3>
                                        </v-chip>
                                    </h3>
                                    <h3 v-if="item.isComplete == false" style="color: #122940">{{ $t('lm-ytm-itkhath-ay-ijraa') }}</h3>
                                </v-toolbar>
                                <v-container fluid>
                                    <!-- <v-row justify="center" align="center" class="mx-2">

                                    <v-spacer />

                                </v-row> -->
                                    <!-- <br/>
                                <br/> -->
                                    <div>
                                        <v-btn @click="orderData(item, 'view')" v-if="item.isComplete" outlined class="btn" color="teal" x-large>
                                            <v-icon class="ml-2">info</v-icon>
                                            <h4>{{ $t('ardh-altfasyl') }}</h4>
                                        </v-btn>
                                        <template v-if="!isReport">
                                            <v-btn @click="orderData(item, 'add')" v-if="!item.isComplete" class="btn" color="blue darken-3" x-large dark>
                                                <v-icon class="ml-2">view_stream</v-icon>
                                                <h4>{{ $t('mtabah-altlb') }}</h4>
                                            </v-btn>
                                        </template>
                                        <template v-if="item.isComplete">
                                            <v-btn @click="orderData(item, 'edit')" class="btn mr-2" color="orange darken-3" x-large dark v-if="!isGuest">
                                                <v-icon class="ml-2">edit</v-icon>
                                                <h4>تعديل الطلب</h4>
                                            </v-btn>
                                        </template>
                                    </div>
                                </v-container>
                            </v-alert>
                        </v-container>
                    </v-card>
                </v-timeline-item>
            </v-timeline>
        </div>
        <Dialog />
        <v-dialog persistent v-model="usersDialog" width="900">
            <v-card>
                <v-card-title v-if="users.length > 0">
                    المستخدمين ( {{users[0].userGroups[0].groupName}} )
                    <v-spacer></v-spacer>
                    <v-btn color="error" icon @click="usersDialog = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text>
                    <v-data-table class="mytable" :items="users" :loading="$global.state.loading" :headers="headers" hide-default-footer disable-pagination :loading-text="$t('loading')" :no-data-text="$t('no-data')" :id="$vuetify.theme.dark ? 'row_colors_dark' : 'row_colors_light'">
                        <template v-slot:item.phoneNumber="{ item }">
                            <div dir="ltr">{{ item.phoneNumber }}</div>
                        </template>

                        <template v-slot:item.imageUrl="{ item }">
                            <v-avatar rounded>
                                <v-img :src="
                  item.imageUrl
                    ? $config.BASE_URL_PROD + '/' + item.imageUrl
                    : $store.state.defaultPhoto
                " />
                            </v-avatar>
                        </template>

                        <template v-slot:item.isActive="{ item }">
                            <v-chip v-if="item.isActive == true" color="teal" dark>
                                {{
                  $t("faal")
                  }}
                            </v-chip>
                            <v-chip v-if="item.isActive == false" color="error" dark>{{ $t("ghyr-mfal") }}</v-chip>
                        </template>

                        <template v-slot:item.userGroups="{ item }">
                            <v-chip v-for="(group, i) in item.userGroups" :key="i" label>
                                <h4>{{ group.groupName }}</h4>
                            </v-chip>
                        </template>
                    </v-data-table>
                </v-card-text>
            </v-card>
        </v-dialog>
    </template>
</div>
</template>

<script>
export default {
    components: {
        Dialog: () => import("./dialog")
    },
    data() {
        return {
            items: [],
            usersDialog: false,
            users: [],
            headers: [{
                    text: "",
                    value: "imageUrl"
                },
                {
                    text: this.$t("alism-althlathy"),
                    value: "fullName"
                },
                {
                    text: this.$t("username"),
                    value: "userName"
                },
                {
                    text: this.$t("almnzmh"),
                    value: "organization.name"
                },
                {
                    text: this.$t("alhalh"),
                    value: "isActive"
                },
                {
                    text: this.$t("almjmwaat"),
                    value: "userGroups"
                }
            ]
        };
    },
    computed: {
        isReport() {
            // if(this.$route.path.search("Report") > -1){
            //   return true;
            // } else {
            //   if(this.$store.state.user.role == "Guest"){
            //     return true;
            //   } else {
            //     return false;
            //   }
            // }
            return this.$route.path.search("Report") > -1;
        },
        isGuest() {
            return this.$route.path.search("guest") > -1;
        }
    },
    created() {
        this.getItems();
    },

    methods: {
        async openUsers(id) {
            this.$global.state.loading = true;
            const res = await this.$http.get(`User`, {
                params: {
                    GroupId: id
                }
            });
            this.users = res.data.result;
            this.usersDialog = true;
            this.$global.state.loading = false;
        },
        orderData(item, actionType) {
            console.log(item);
            this.$store.commit("setOrder", item);
            let goTo = this.isGuest ? "/guest" : ""
            this.$router.push({
                path: goTo +
                    "/orderData/" +
                    item.node.id +
                    "/" +
                    item.node.name +
                    "/" +
                    item.node.mainNodes[0].mainNodeId +
                    "/" +
                    this.$route.params.orderId +
                    "/" +
                    item.id +
                    "/" +
                    item.node.workflow.id +
                    "/" + actionType
            });
        },
        async getItems() {
            this.$global.state.loading = true;
            try {
                let GroupIds = [];
                if (this.$store.state.user.user.userGroups.length > 0) {
                    await this.$store.state.user.user.userGroups.forEach(element => {
                        GroupIds.push(element.groupId);
                    });
                }
                let url = `OrderNode?OrderId=${this.$route.params.orderId}&IsDeleted=false&Sort=desc&PageIndex=1&PageSize=100`;
                if (!this.isReport) {
                    for (let j = 0; j < GroupIds.length; j++) {
                        url = url + `&GroupIds=${GroupIds[j]}`;
                    }
                }
                const res = await this.$http.get(url);
                this.items = res.data.result;
            } catch (err) {
                console.log(err);
            } finally {
                this.$global.state.loading = false;
            }
        },

        openDetailsDialog(item) {
            item.dialogType = "add";
            this.$store.commit("setItemDetails", item);
            this.$store.commit("setDialog2");
            this.$eventBus.$emit("fill-fields");
        }
    }
};
</script>
